
import { CrudService } from "../core/services/crudService";
import { Settings } from "../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: {},
})
export default class PublicTicket extends Vue {
  data: any = null;
  errorMsg: string = "";
  finished: boolean = false;
  async mounted() {
    await this.loadData();
  }

  count: number = 10;
  async loadData() {
    let url = `${Settings.HostName}/api/publictickets/download-ticket/${this.$route.params.token}`;
    fetch(url, {
      method: "GET",
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Handle the PDF blob
        this.handlePDFResponse(blob);
        this.finished = true;
      })
      .catch((error) => {
        // Handle errors
        console.error("Error downloading PDF:", error);
      });
  }

  handlePDFResponse(response) {
    // Create a Blob from the binary data
    const blob = new Blob([response], { type: "application/pdf" });

    // Create a download link
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "ticket.pdf";

    // Append the link to the DOM and trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
  }
}
