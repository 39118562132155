
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CtaFullWidth extends Vue {
  @Prop() title: any;
  @Prop() description: any;

  mounted() {}
}
