import { render, staticRenderFns } from "./dynamicForm.vue?vue&type=template&id=30dd1868&scoped=true&"
import script from "./dynamicForm.vue?vue&type=script&lang=ts&"
export * from "./dynamicForm.vue?vue&type=script&lang=ts&"
import style0 from "./dynamicForm.vue?vue&type=style&index=0&id=30dd1868&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../padel/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30dd1868",
  null
  
)

export default component.exports